import React from 'react'
import { graphql, useStaticQuery } from "gatsby"

const useSiteSettings = () => {

  const siteSettings = useStaticQuery(graphql`
    query {
      sanitySiteSettings(id: {eq: "-0f217bb5-f7f6-5420-b7c6-58db2c12b8c7"}) {
        siteTagline
        siteTitle
        newsletterSignUpText
        popUpContent
        popUpText: _rawPopUpText(resolveReferences: {maxDepth: 5})
        showPopUp
        popUpImage {
          asset {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
        quizPopUpText: _rawQuizPopUpText(resolveReferences: {maxDepth: 5})
        quizPopUpImage {
          asset {
            fluid {
              aspectRatio
              base64
              sizes
              src
              srcSet
              srcSetWebp
              srcWebp
            }
          }
        }
        quizPopUpButtonText
        signUpPopUpText
        showSalesMotivator
        salesMotivatorTitle
        salesMotivatorText
        freeProduct {
          content {
            shopify {
              defaultVariant {
                variantId
              }
            }
          }
        }
        discountCode
        minSalesMotivator
        salesMotivatorSuccessMessage
      }
    }
    `)

  return siteSettings.sanitySiteSettings

  // return {
  //   siteTitle: settings.siteTitle,
  //   siteTagline: settings.siteTagline,
  //   newsletterSignUpText: settings.newsletterSignUpText,
  //   popUpContent: settings.popUpContent,
  //   popUpText: settings.popUpText,
  //   showPopUp: settings.showPopUp,
  //   popUpImage: settings.popUpImage,
  //   signUpPopUpText: settings.signUpPopUpText,
  //   quizPopUpText: settings.quizPopUpText,
  //   quizPopUpImage: settings.quizPopUpImage,
  //   quizPopUpButtonText: settings.quizPopUpButtonText,
  //   showSalesMotivator,
  //   salesMotivatorTitle,
  //   salesMotivatorText,
  //   freeProduct,
  // }
}

export default useSiteSettings
