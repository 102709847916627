/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

// You can delete this file if you're not using it

import React from "react"
import axios from "axios"
import isbot from 'isbot'

import { SiteStore, useDefaultOrder } from "~context/siteContext"

export const wrapRootElement = ({ element }) => (
  <SiteStore>{element}</SiteStore>
)

const productRoutes = ['/coffee', '/tea', '/olive-oil', '/extras']

export const shouldUpdateScroll = ({ prevRouterProps, routerProps }) => {
  if (!prevRouterProps || routerProps.location.hash) return false
  const fromProduct = productRoutes.filter(r => prevRouterProps.location.pathname?.includes(r))
  const toProduct = productRoutes.filter(r => routerProps.location.pathname?.includes(r))
  let shouldNotUpdate = (toProduct.length && toProduct[0] === fromProduct[0])
  return !shouldNotUpdate
}

// export const onRouteUpdate = ({ location, prevLocation }) => {
//
//   const toProduct = productRoutes.filter(r => location.pathname?.includes(r))
//   const [defaultOrder, setDefaultOrder] = useDefaultOrder()
//
//   if(prevLocation){
//     if(prevLocation.pathname === '/subscribe-save' && toProduct){
//       setDefaultOrder('sub')
//     }
//   }
//   // location.pathname
//   // console.log("new pathname", location.pathname)
//   // console.log("old pathname", prevLocation ? prevLocation.pathname : null)
// }

// export const onRouteUpdate = ({ location, prevLocation }) => {
//   if (location && location.state)
//     location.state.referrer = prevLocation ? prevLocation.pathname : null
// }

export const onInitialClientRender = () => {
  const botDetected = isbot(navigator.userAgent) 
  console.log(botDetected)
  // if(!window.location.hostname.includes('localhost')){
  //   axios.get('https://api.db-ip.com/v2/p8db400b1b1559bbc7a3cbacbd4c274c955af531/self').then(res => {
  //     let code = res.data?.countryCode
  //     if(['US', 'CA'].includes(code)){
  //       window.setTimeout(() => {
  //         window.location = 'https://us.podandparcel.com/'
  //       }, 1000)
  //     }
  //   })
  // }
}

export const onRouteUpdate = ({ location, prevLocation }) => {
  // if(location.hash){
  //   console.log(location.hash)
  //   const hashedEl = document.querySelector(location.hash)
  //   console.log(hashedEl)
  //   if(hashedEl){
  //     const distFromTop = hashedEl.getClientRects()[0].top
  //     console.log(getClientRects[])
  //     console.log(distFromTop)
  //     window.scrollTo(0, distFromTop - 120)
  //     // hashedEl.scrollIntoView({block: "start"})
  //   }
  // }

  const eventID = 'FBEI_' + new Date().getTime();

  // Don't track while developing.
  // eslint-disable-next-line no-undef
  if (process.env.NODE_ENV === `production` && typeof fbq !== `undefined`) {
    // eslint-disable-next-line no-undef
    // fbq("track", "ViewContent", {eventID: eventID})
  }

  window.locations = window.locations || [document.referrer]
  window.locations.push(window.location.href)
  window.previousPath = window.locations[window.locations.length - 1]

}
