// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-extras-js": () => import("./../../../src/pages/extras.js" /* webpackChunkName: "component---src-pages-extras-js" */),
  "component---src-pages-learn-js": () => import("./../../../src/pages/learn.js" /* webpackChunkName: "component---src-pages-learn-js" */),
  "component---src-pages-subscribe-js": () => import("./../../../src/pages/subscribe.js" /* webpackChunkName: "component---src-pages-subscribe-js" */),
  "component---src-templates-article-js": () => import("./../../../src/templates/article.js" /* webpackChunkName: "component---src-templates-article-js" */),
  "component---src-templates-brew-guide-js": () => import("./../../../src/templates/brewGuide.js" /* webpackChunkName: "component---src-templates-brew-guide-js" */),
  "component---src-templates-extra-js": () => import("./../../../src/templates/extra.js" /* webpackChunkName: "component---src-templates-extra-js" */),
  "component---src-templates-group-js": () => import("./../../../src/templates/group.js" /* webpackChunkName: "component---src-templates-group-js" */),
  "component---src-templates-page-js": () => import("./../../../src/templates/page.js" /* webpackChunkName: "component---src-templates-page-js" */),
  "component---src-templates-quiz-js": () => import("./../../../src/templates/quiz.js" /* webpackChunkName: "component---src-templates-quiz-js" */)
}

